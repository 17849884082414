<template>
  <b-row>
    <b-col
      md="12"
      class="p-4"
    >
      <b-card>
        <div class="py-2 text-center">
          <vuexy-logo />
        </div>
        <b-card-header class="pl-0 pt-0">
          <b-card-title>{{ $t('Registrations') }}</b-card-title>
        </b-card-header>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('Name')">
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="form.name"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Full Name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('Address')">
                  <validation-provider
                    #default="{ errors }"
                    name="company address"
                    rules="required"
                  >
                    <b-form-input
                      v-model="form.address"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Company Address')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('Email')">
                  <validation-provider
                    #default="{ errors }"
                    name="company email"
                    rules="required|email"
                  >
                    <b-form-input
                      v-model="form.email"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Email')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('Password')">
                  <validation-provider
                    #default="{ errors }"
                    vid="password"
                    :name="$t('Password')"
                    rules="required|min:8"
                  >
                    <b-input-group
                      :class="errors.length > 0 ? 'is-invalid':null"
                      class="input-group-merge"
                    >
                      <b-form-input
                        v-model="form.password"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false : null"
                        autocomplete="off"
                        :placeholder="$t('Company Password')"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="() => passwordFieldType = passwordFieldType === 'password' ? 'text' : 'password'"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('Confirm Password')">
                  <validation-provider
                    #default="{ errors }"
                    name="confirm password"
                    rules="required|confirmed:password|min:8"
                  >
                    <b-input-group
                      :class="errors.length > 0 ? 'is-invalid':null"
                      class="input-group-merge"
                    >
                      <b-form-input
                        v-model="form.password_confirmation"
                        :type="passwordFieldTypeRetype"
                        :state="errors.length > 0 ? false : null"
                        autocomplete="off"
                        :placeholder="$t('Password Confimation')"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIconRetype"
                          class="cursor-pointer"
                          @click="() => passwordFieldTypeRetype = passwordFieldTypeRetype === 'password' ? 'text' : 'password'"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.type"
                name="some-radios"
                value="0"
              >
                Individual
              </b-form-radio>
              <b-form-radio
                v-model="form.type"
                name="some-radios"
                value="1"
              >
                Team
              </b-form-radio>
            </div> -->
            <div
              v-if="form.type == 1"
              class="pt-2"
            >
              <h3>{{ $t('Company Info') }}</h3>
              <b-row>
                <b-col md="6">
                  <b-form-group :label="$t('Company Name')">
                    <validation-provider
                      #default="{ errors }"
                      name="company name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="form.company.name"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('Company Name')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group :label="$t('Company Email')">
                    <validation-provider
                      #default="{ errors }"
                      name="company email"
                      rules="required|email"
                    >
                      <b-form-input
                        v-model="form.company.email"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('Company Email')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group :label="$t('Company Address')">
                    <validation-provider
                      #default="{ errors }"
                      name="company address"
                      rules="required"
                    >
                      <b-form-input
                        v-model="form.company.address"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('Company Address')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>
        <div>
          <b-row>
            <b-col
              md="12"
              class="d-flex justify-content-end"
            >
              <b-button
                variant="primary"
                :disabled="isProcessing"
                @click="handleOk"
              >
                <b-spinner
                  v-show="isProcessing"
                  small
                />
                {{ $t('Register') }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  VBModal, BForm, BFormGroup, BFormInput, BSpinner, BButton, BRow, BCol,
  BCard, BCardHeader, BCardTitle, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import useApollo from '@/plugins/graphql/useApollo'
import useJwt from '@/auth/jwt/useJwt'
import VuexyLogo from '@core/layouts/components/Logo.vue'

const defaultForm = () => ({
  id: null,
  name: '',
  type: '0',
  password: '',
  email: '',
  address: '',
  password_confirmation: '',
  company: {
    name: '',
    email: '',
    address: '',
  },
})
export default {
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    ValidationObserver,
    BCard,
    BSpinner,
    BButton,
    BCardHeader,
    BCardTitle,
    BInputGroup,
    BInputGroupAppend,
    VuexyLogo,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      required,
      email,
      title: this.$t('Create Company'),
      isProcessing: false,
      form: {
        ...defaultForm(),
      },
      passwordFieldType: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    handleOk() {
      // bvModalEvt.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useApollo.auth.register({
            name: this.form.name,
            password: this.form.password,
            password_confirmation: this.form.password_confirmation,
            email: this.form.email,
            company: Number(this.form.type) === 1 ? this.form.company : null,
          })
            .then(response => {
              const userData = response.data.register
              localStorage.setItem('userData', JSON.stringify(userData.user))
              useJwt.setToken(userData.tokens.access_token)
              this.$router.replace({ name: 'home' }).then(() => {
              // this.$store.dispatch('authEvents/onUserLogin')
                this.showSuccessMessage({
                  data: {
                    message: `Welcome ${userData.user.name}, your account has been created & you are logged in`,
                  },
                })
              })
            }).catch(error => {
              this.showError(error.toString())
            })
            .finallly(() => {
              this.isProcessing = false
            })
        }
      })
    },
  },
}
</script>
